.sub-header {
  margin: 40px 0;
  position: fixed;
  z-index: 99;
  align-items: flex-end;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
}

.sub-header hr {
  margin-bottom: 0;
}

.sub-header .row {
  margin: 10px;
}

.title {
  color: #929c7c;
}

@media (max-width: 575px) {
  .subheader-offset {
    height: 0px;
  }
}

@media (min-width: 576px) and (max-width: 990px) {
  .subheader-offset {
    height: 0px;
  }
}

@media (min-width: 991px) {
  .subheader-offset {
    height: 21px;
  }
}


.svg-color-red svg path {
  background-color: red;
}
