.open-instrucao-wrapper {
  display: flex;
  align-items: flex-end;
}

.open-instrucao {
  margin: 0 0 !important;
  cursor: pointer;
}

.open-instrucao > h4 {
  color: #002a4b;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
  display: flex;
  align-items: center;
  text-decoration: underline;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .open-instrucao > * {
    font-size: calc(12px + (14 - 12) * ((100vw - 1000px) / (1500 - 1000)));
  }
}

@media (min-width: 1500px) {
  .open-instrucao > * {
    font-size: 14px;
  }
}

.open-instrucao > div {
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  background-color: #002a4b;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
