body {
    background-color: #fff;
  }

  p {
    margin-bottom: 0 !important;
  }

  .App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-x: hidden;
    flex-direction: column;
  }

  .row {
    min-width: 100%;
  }

  .nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .no-wrap-visible {
    overflow: hidden;
    white-space: nowrap;
  }

  .one-line-text {
    overflow: hidden;
    margin-right: 2em;
    width: min-content;
    white-space: nowrap;
    max-width: 80%;
    text-overflow: ellipsis;
  }

  .row.without-margin {
    margin: 0;
  }

  .row-centered {
    align-items: center;
  }

  .justify-content-center {
    justify-content: center;
  }

  .full-width {
    width: 100%;
  }

  .space-between {
    justify-content: space-between;
  }

  .without-padding {
    padding: 0 !important;
  }

  #no-padding-right {
    padding-right: 0 !important;
  }

  .no-break {
    display: flex;
    flex-wrap: nowrap;
  }

  .row.no-break {
    flex-wrap: nowrap;
  }

  .scroll {
    overflow: auto;
  }

  .full-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiInputBase-input {
    font-size: 12px;
  }
  @media (min-width: 1000px) and (max-width: 1500px) {
    .MuiInputBase-input {
      font-size: calc(12px + (14 - 12) * ((100vw - 1000px) / (1500 - 1000)));
    }
  }

  @media (min-width: 1500px) {
    .MuiInputBase-input {
      font-size: 14px;
    }
  }

  .without-position {
    position: static !important;
  }

  .min-width {
    min-width: min-content;
  }

  #color-to-blue {
    filter: invert(30%) grayscale(100%) brightness(0%) contrast(4);
  }

  .instrument-container {
    padding: 5vh 2vw;
  }

  *::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #F5F5F5;
  }

  *::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5;
  }

  *::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #707070;
  }

  * {
    font-family: "Montserrat", sans-serif;
  }
  .moneyBtn {
    color: #fff;
    font-size: 11px;
  }
  .buscaInput {
    border-radius: 10px;
    background: #232639;
    padding-left: 10px;
    border: 0;
    color: #fff;
    width: 300px;
  }
  .sidebar .sidebar-header {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0.75rem 1rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    margin-top: 20px;
  }
  .sidebar .sidebar-header img {
    width: 50%;
  }

  .logo {
    width: 200px;
  }

  .add {
    border-radius: 20px;
    color: #fff;
    margin: 5px;
    padding: 10 15;
  }

  .inputText {
  }

  .instrumento {
    font-size: 12px;
    color: #fff;
    width: 100%;
    height: 50px;
    white-space: normal;
    margin-bottom: 10px;
  }

  .isntEscolhido {
    padding: 10px;
  }
  input,
  button {
    outline: 0;
  }

  .step {
    background-color: #ccc;
    padding: 10px;
  }

  .drop {
    color: white;
    border-radius: 5px;
    padding: 10px;
    margin: 3px;
    background-color: #005577;
  }

  .dropBar {
    height: 200px;
    border: solid 1px #333333;
    width: 100%;
  }
  .icone {
    padding: 0px 0px;
    margin: 0px;
  }

  .drag_things_to_boxes .component_box {
    float: left;
  }

  .drag_things_to_boxes .box {
    border: 2px solid black;
    border-radius: 4px;
    width: 140px;
    height: 276px;
    margin: 10px 80px 0 0;
    position: relative;
  }

  .drag_things_to_boxes .highlighted .box {
    box-shadow: inset 0 0 4px #00f;
  }

  .box_item_component {
    width: 100%;
  }

  .box_item_component .outer {
    border-top: none;
    min-width: 190px;
    max-width: 140px;
    overflow: hidden;
  }

  .box_item_component .highlighted .outer {
    border-top: 40px solid #ddd;
  }

  .box_item_component .item {
    color: white;
    border-radius: 5px;
    padding: 10px;
    margin: 3px;
    background-color: #005577;
  }

  .box_item_component .grabber {
    font-size: 14px;
    font-weight: bold;
    padding-right: 6px;
  }

  .loginBtn {
    width: 100%;
  }

  .esq {
    font-size: 12px;
  }

  .disabled {
    color: red;
  }
  .instAc {
    padding: 10px;
    padding-bottom: 0px;
    margin: 0px;
    border: 0px;
    border-radius: 0px;
  }
  .base {
    padding-top: 10px;
  }
  .progressBarra {
    margin-top: 4px;
  }
  .progressBarra p {
    font-size: 10px;
  }

  .profile {
    width: 32px;
    height: 32px;
    border-radius: 32px;
  }

  .selectWho {
    margin: 20px;
    padding: 0px;
  }

  .dta {
    border: 0px;
  }

  .gp {
    min-height: 300px;
  }

  .fa-check {
    color: green;
  }

  .time {
    color: #fff;
    background: transparent;
    border: 0px;
    width: 50px;
  }

  .titulo {
    font-weight: bold;
    font-size: 10px;
  }
  .sub {
    border-top: dashed 1px #ffffff;
  }
  .sub2 {
    border-top: dashed 1px #ffffff;
  }

  .notReady {
    color: #999999;
  }

  .ready {
    color: green;
  }
  .bg-yellow {
    background-color: #ffd800;
  }
  h1 {
    font-size: 20px;
  }

  th {
    font-size: 10px;
  }

  .appster {
    display: flex !important;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    height: 100%;
    width: 100%;
    /* padding: 20px; */
  }

  .app-header {
    background: #002a4c;
    border: 0px;
  }

  .icon-magnifier {
    filter: invert(1);
  }

  .menuLateral {
    background: #002a4c;
  }

  .sidebar-header {
    margin-top: -10px !important;
    padding-top: 30px !important;
    background: #07536d !important;
  }

  .avatrAjuste {
    width: 60px !important;
    border-radius: 50em;
  }

  .sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
    position: -webkit-sticky !important;
    position: -moz-sticky !important;
    position: -ms-sticky !important;
    position: -o-sticky !important;
    top: 5px;
  }

  /* .csv-reader-input {
    width: 100%;
    label {
      font-size: 12px;
    }
  } */

  /* .baseFoto {
    button {
      border: 0px;
    }
  } */

  .demo-editor {
    border: solid 1px lightgray;
    height: 300px !important;
    padding: 20px;
  }

  /* .remove {
    background: transparent;
    border: 0;
    :focus {
      outline: 0;
    }
  } */

  .w-60 {
    width: 60% !important;
  }

  .w-63 {
    width: 63% !important;
  }

  .w-89 {
    width: 89% !important;
  }
