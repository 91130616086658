
.historia-de-vida {
  display: flex;
  flex-direction: column;
  padding: 2vh 0;
}

.wrapper-historia-de-vida {
  background-color: #fff;
  border: 1px solid #c9c9c9;
  height: 100%;
}

.editor-historia-de-vida {
 height: 80%;
}

.wrapper-historia-de-vida {
  height: 250px;
  max-height: 250px;
  max-width: 1024px;
}


.DraftEditor-editorContainer,
.DraftEditor-root {

  max-height: 100%;
  height: 100%;
}

.DraftEditor-editorContainer {
  overflow: auto;
}
