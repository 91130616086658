.remove-margins * {
  margin: 0;
}

.question {
  height: auto;
}

.radio-wrapper {
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 50px;
}

.radio-wrapper-Ancora {
  height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 50px;
  border: 2px solid #ccc;

}

.modal-padding{
  padding-left: 10px;
  padding-right: 10px;
}

.margin-top {
  margin-top: 20px;
}

.feedback {
  min-width: 60%;
  min-height: 30vh;
}

.instrument-screen {
  background-color: #f8f8f8;
  font-size: 10px;
}

.instrument-screen b {
  font-size: 12px;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .instrument-screen {
    font-size: calc(12px + (12 - 10) * ((100vw - 1000px) / (1500 - 1000)));
  }
  .instrument-screen b {
    font-size: calc(14px + (14 - 12) * ((100vw - 1000px) / (1500 - 1000)));
  }
}

@media (min-width: 1500px) {
  .instrument-screen {
    font-size: 12px;
  }
  .instrument-screen b {
    font-size: 14px;
  }
}

.send-button {
  /* background-color: #00264b !important; */
  color: #fff !important;
  border: 0 !important;
  margin: 20px 20px;
  padding: 10px 20px;
  font-weight: 700;
  box-shadow: none !important;
}

.atividade-btn {
  font-weight: bold;
  padding: 10px 15px 10px 15px;
}

.atividade-btn:hover {
  color: #141313;
  background-color: #87a5bc;
  border: none;
}

.atividade-btn > button:hover {
  color: #857979;
  background-color: #87a5bc;
  border: none;
}

.dark-blue-bg {
  background: #1c2135;
}

.light-blue-bg  {
  background: #01a9b5 !important;
}
