/* Customize the label (the container) */
.container-radio {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark-radio {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d2d2d2;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark-radio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark-radio {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark-radio:after {
  /* top: 17.5%;
  left: 17.5%; */
  width: 110% !important;
  height: 110% !important;
  padding-bottom: 65% !important;
  border-radius: 100% !important;
  background: #002449 !important;
}

#right-margin {
  margin: 0 10px 0 0;
}
@media (min-width: 1000px) and (max-width: 1500px) {
  .container-radio {
    font-size: calc(10px + (12 - 10) * ((100vw - 1000px) / (1500 - 1000)));
  }
}

@media (min-width: 1500px) {
  .container-radio {
    font-size: 12px;
  }
}
