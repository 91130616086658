.header-participante {
  background-color:  #002a4c;
  color: #fff;
  padding: 10px;
}

.header-participante>.row {
  align-items: center;
  justify-content: flex-end;
}

.nome-header {
  background-color: transparent !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: 700;
  text-decoration: underline !important;
}

.nome-header-wrapper {
  display: flex;
  justify-content: flex-end;
}

nav {
  .btn-secondary {
    color: #ffffff;
    background-color: transparent;
    border: none;
  }

  .btn-secondary:hover {
    color: #ffffff;
    background-color: transparent;
    border: none;
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show>.btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: transparent;
    border: none;
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: none;
  }
}

.show>button {
  background-color: transparent;
  border: none;
  color: white;
}
