.sub-header {
  margin: 40px 0;
  position: fixed;
  z-index: 99;
  align-items: flex-end;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
}

.sub-header hr {
  margin-bottom: 0;
}

.sub-header .row {
  margin: 10px;
}

.title {
  color: #929c7c;
}

@media (max-width: 575px) {
  .subheader-offset-dash {
    height: 80px;
  }
}

@media (min-width: 576px) {
  .subheader-offset-dash {
    height: 120px;
  }
}
