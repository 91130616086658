.campo-text-area > textarea {
  border: 1px solid #c9c9c9;
  width: 100%;
  min-height: 30px;
  resize: none;
}

.campo-text-area {
  margin: 0 0 2em 0;
}

@media (max-width: 730px) {
  .feedback-text-area {
    max-width: 300px;
    margin-left: 50px;
  }
}

@media (max-width: 575px) {
  .campo-text-area {
    margin-top: 10px;
  }

  .feedback-text-area {
    max-width: 100%;
    margin-left: 0px;
  }
}
